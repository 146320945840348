/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
// @ts-nocheck
import { useRef, useState, useEffect } from "react";
import "../../addProperty/property.scss";
import Layout from "../../../layout";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../components/button/primaryButton";
import { Rating } from "react-simple-star-rating";
import {
  useBuyTokenMutation,
  useClaimProfitMutation,
  useChangeFavStatusMutation,
  useLazyGetPropertyDetailByIdQuery,
  useSetTokenOnSaleMutation,
  useGetDividendMutation,
  useMintPostMutation,
  useLazyUserHoldingsQuery,
} from "../../../service/Property";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import {
  GetPropertyDetail,
  PropertydividentRes,
  SerializedData,
  UserHoldings,
} from "../../../types/General";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Tooltip,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Milestone } from "../../../features/propertyDetail";
import { ABI, ContractAddress, USDTAbi } from "../../../utils/configuration";
import Web3 from "web3";
import useAuth from "../../../hooks/useAuth";
import { USDIAddress } from "../../../utils/configuration";
import { propertyTokenAbi } from "../../../utils/configuration";
import { propertyTokenAddress } from "../../../utils/configuration";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { isNumber } from "../../../utils/validation";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  useAddReportMutation,
  useLazyGetRatingQuery,
  usePostRatingMutation,
} from "../../../service/Common";

const PropertyDetail = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const OwnerRef = useRef(null);
  const ContractRef = useRef(null);
  const userData = useAuth();
  const { id } = useParams();
  const [propertyDetailMutaion, { isLoading }] =
    useLazyGetPropertyDetailByIdQuery();

  const [changeFavStatus] = useChangeFavStatusMutation();
  const [buytokenMutation, buyTokenData] = useBuyTokenMutation();
  const [claimProfitMutation, claimProfitData] = useClaimProfitMutation();
  const [setTokenOnSaleMutatation, setTokenOnSaleMutatationData] =
    useSetTokenOnSaleMutation();
  const [dividendMutation] = useGetDividendMutation();
  const [addRating] = usePostRatingMutation();
  const [getRatingMutation] = useLazyGetRatingQuery();
  const [addReport] = useAddReportMutation();

  const [propertyDetail, setPropertyDetail] = useState<GetPropertyDetail>();
  const [propertyTax, setPropertyTax] = useState<PropertydividentRes>();
  const [serializedData, setSerializedData] = useState<SerializedData>();
  const [open_gallery, setOpen_gallery] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [open, setOpen] = useState(false);
  console.log("open: ", open);
  const [loading, setLoading] = useState<boolean>(false);
  const [userHolding, setUserHolding] = useState<UserHoldings>();
  console.log("userHolding: ", userHolding);
  const [error, setError] = useState<boolean>(false);
  const [errorSale, setErrorSale] = useState<boolean>(false);
  const [noOfToken, setNoOfToken] = useState("");
  const [noOfTokenSale, setNoOfTokenSale] = useState("");
  const [openInterior, setOpenInterior] = useState(false);
  const [appliceOpen, setApplianOpen] = useState(false);
  const [buildingOpen, setBuildingOpen] = useState(false);
  const [otherOpen, setOtherOpen] = useState(false);
  const [parkingOpen, setParkingOpen] = useState(false);
  const [exteriorOpen, setExteriorOpen] = useState(false);
  const [copyContract, setCopyContract] = useState<string>("");
  const [copyOwner, setCopyOwner] = useState<string>("");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentMonthName, setCurrentMonthName] = useState("");
  const [totalDividend, setTotalDivident] = useState<string>("");
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [ratingCount, setratingCount] = useState(0);
  const [comment, setComment] = useState<string>("");
  const [report, setReport] = useState<string>("");

  //handle report
  const handleReport = async (id: string) => {
    setError(true);
    if (!report) {
      return;
    }
    const body = {
      message: report,
    };
    try {
      const response = await addReport({
        id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Report Sent Successfully");
        setReport("");
        setError(false);
        setReportModal(false);
      } else {
        errorToast(response?.message || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const today = new Date();

    setCurrentMonthName(monthNames[today.getMonth()]);
  }, []);
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(ABI, ContractAddress);

  const UsdtContract = new web3.eth.Contract(USDTAbi, USDIAddress);
  const propertyContract = new web3.eth.Contract(
    propertyTokenAbi,
    propertyTokenAddress
  );
  const getPropertyDetail = async () => {
    try {
      const response = await propertyDetailMutaion({
        property_id: id,
        month: monthNames[currentMonth.getMonth()],
      }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyDetail(response?.data?.property[0]);
        setPropertyTax(response?.data?.propertyData[0]);
        setSerializedData(response?.data?.serializedData || null);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleFav = async (id: string | undefined) => {
    try {
      const response = await changeFavStatus({ property_id: id }).unwrap();
      if (response?.statusCode === 200) {
        getPropertyDetail();
      } else {
        errorToast("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle claim
  // const handleClaimProfit = async () => {
  //   try {
  //     if (contract) {
  //       setLoading(true);

  //       const gas = await contract.methods

  //         .claimProfit(propertyDetail?.propertyId) //property Id
  //         .estimateGas({ from: userData?.walletAddress });
  //       let claimProfit = await contract.methods
  //         .claimProfit(propertyDetail?.propertyId) //property Id
  //         .send({ from: userData?.walletAddress, gas: parseInt(gas) });
  //       if (claimProfit) {
  //         successToast("Profit claimed successfully");
  //         setTimeout(() => {
  //           getUserTokenbyBlockchain();
  //         }, 2000);
  //         setTimeout(() => {
  //           claimProfitApi(Number(userHolding?.profitClaimedTill));
  //         }, 3000);
  //       }

  //       setLoading(false);
  //     }
  //   } catch (error: any) {
  //     errorToast(error?.data?.message || "Please try again");
  //     setLoading(false);
  //   }
  // };

  //getUserToken
  // const getUserTokenbyBlockchain = async () => {
  //   try {
  //     if (contract) {
  //       const getUserToken = await contract.methods
  //         .BuyerDetails(userData?.walletAddress, propertyDetail?.propertyId)
  //         .call((error: any, result: any) => {
  //           if (!error) {
  //             console.log(
  //               `Balance for user with key ${
  //                 (userData?.walletAddress, propertyDetail?.propertyId)
  //               }: ${result}`
  //             );
  //           } else {
  //             console.error(error);
  //           }
  //         });

  //       setUserHolding(getUserToken);
  //     }
  //   } catch (error: any) {
  //     console.log(error, "error");
  //     // errorToast(error?.message || "");
  //   }
  // };

  const [userHoldingMutation] = useLazyUserHoldingsQuery();
  const getUserHolding = async (id: string) => {
    try {
      const res = await userHoldingMutation({ property_id: id }).unwrap();
      if (res?.statusCode === 200) {
        setUserHolding(res?.data || null);
      }
    } catch (error) {
      console.log(error, "eroro");
    }
  };

  //Minting function
  const [mintingMutation, mintingMutationData] = useMintPostMutation();

  const handleMinting = async (id: any) => {
    console.log("id:handleMinting ", id);
    setError(true);
    if (!noOfToken || Number(noOfToken) > Number(propertyDetail?.totalTokens)) {
      return;
    } else {
      setError(false);
    }
    const body = {
      noOfTokens: Number(noOfToken),
    };
    try {
      const response = await mintingMutation({
        property_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getPropertyDetail();
        successToast(`You have minted ${noOfToken} number of tokens`);
        setNoOfToken("");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  //buy token api
  const BuyTokenApi = async (id: string | undefined) => {
    const body = {
      tokenAmounts: noOfToken,
    };
    try {
      const response = await buytokenMutation({
        property_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        setOpen(false);
        getPropertyDetail();
        successToast(`You have bought ${noOfToken} number of tokens`);
        setNoOfToken("");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  //claim Profit api
  const claimProfitApi = async (profit: number) => {
    const body = {
      profitAmount: profit,
    };
    try {
      const response = await claimProfitMutation({
        property_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Profit claimed successfully");
        getPropertyDetail();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  //get divident api
  const GetDividend = async (id: string) => {
    try {
      const response = await dividendMutation({
        property_id: id,
        month: monthNames[currentMonth.getMonth()],
      }).unwrap();
      if (response?.statusCode === 200) {
        setTotalDivident(response?.data || "");
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  //set token on sale api
  const setTokenOnSaleApi = async (id: string) => {
    const body = {
      onSellTokens: noOfTokenSale,
    };
    try {
      const response = await setTokenOnSaleMutatation({
        property_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        getPropertyDetail();
        successToast(`You have set ${noOfTokenSale} tokens on sale`);
        setNoOfTokenSale("");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  //sold token api
  // const soldTokenApi = async () => {
  //   const body = {
  //     soldTokens: noOfTokenSale,
  //   };
  //   try {
  //     const response = await soldTokenMutation(body).unwrap();
  //     if (response?.statusCode === 200) {
  //       console.log("response: claim", response);
  //     }
  //   } catch (error: any) {
  //     errorToast(error?.data?.message || "");
  //   }
  // };

  //copy contract address
  const copyContractAddress = (item: any) => {
    navigator.clipboard.writeText(item);
    setCopyContract("Copied.");
    setTimeout(() => {
      setCopyContract("");
    }, 2000);
  };

  //copy owner address
  const copyOwnerAddress = (item: any) => {
    navigator.clipboard.writeText(item);
    setCopyOwner("Copied.");
    setTimeout(() => {
      setCopyOwner("");
    }, 2000);
  };

  useEffect(() => {
    getPropertyDetail();
  }, [monthNames[currentMonth.getMonth()]]);

  useEffect(() => {
    if (id) {
      getUserHolding(id);
    }
  }, []);

  const decreaseMonth = () => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(newMonth.getMonth() - 1);
    setCurrentMonth(newMonth);
  };

  const increaseMonth = () => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(newMonth.getMonth() + 1);
    setCurrentMonth(newMonth);
  };

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  //rating
  const handleSubmit = async () => {
    const body = {
      rating: ratingCount || 0,
      review: comment,
    };
    try {
      const response = await addRating({ body, property_id: id }).unwrap();
      if (response?.statusCode === 200) {
        successToast(`You've rated this property ${ratingCount}/5`);
        setOpenModal(false);
        setError(false);
        setComment("");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getRating = async () => {
    try {
      const res = await getRatingMutation({ property_id: id }).unwrap();
      if (res?.statusCode === 200) {
        setratingCount(res?.data?.rating || 0);
      }
    } catch (error) {
      console.log(error, "eroro");
    }
  };

  useEffect(() => {
    getRating();
  }, []);

  return (
    <Layout>
      <Loader
        isLoad={
          setTokenOnSaleMutatationData?.isLoading ||
          claimProfitData?.isLoading ||
          isLoading ||
          loading ||
          buyTokenData?.isLoading ||
          mintingMutationData?.isLoading
        }
      />

      <main>
        <section className="propertyDetail_sc u_spc">
          <div className="conta_iner">
            <div className="property_gallery">
              <div className="gap_p">
                <div className="main_gallery">
                  {propertyDetail?.propertyImages?.length ? (
                    <figure>
                      <img
                        src={propertyDetail?.propertyImages?.[0] || ""}
                        alt=""
                      />

                      {propertyDetail?.investmentType ? (
                        <span className="tag">
                          {propertyDetail?.investmentType || ""}
                        </span>
                      ) : null}
                    </figure>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                      animation="wave"
                      style={{ borderRadius: 20 }}
                    />
                  )}
                </div>
                <div className="other_gallery">
                  {propertyDetail ? (
                    <figure>
                      <img
                        src={propertyDetail?.propertyImages?.[1] || ""}
                        alt=""
                      />
                      <span
                        className="more"
                        onClick={() => {
                          setOpen_gallery(true);
                          setShowVideo(false);
                        }}
                      >
                        <img src="/images/icon_image.svg" alt="" />
                        Show all photos
                      </span>
                    </figure>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                      animation="wave"
                      style={{ borderRadius: 20 }}
                    />
                  )}

                  {propertyDetail ? (
                    <figure>
                      <img
                        src={propertyDetail?.propertyImages?.[2] || ""}
                        alt=""
                      />
                      <span
                        className="more"
                        onClick={() => {
                          setOpen_gallery(true);
                          setShowVideo(true);
                        }}
                      >
                        <img src="/images/icon_image.svg" alt="" />
                        Show all videos
                      </span>
                    </figure>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                      animation="wave"
                      style={{ borderRadius: 20 }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="property_description">
              <div className="gap_p">
                <div className="left_side">
                  <div className="fav_div">
                    <h2>{propertyDetail?.propertyTitle || ""}</h2>{" "}
                    <span onClick={() => handleFav(propertyDetail?._id)}>
                      {propertyDetail?.isFavourite ? (
                        <FavoriteIcon />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </span>
                  </div>
                  <ul>
                    <li>
                      <img src="../../images/icon_bed.svg" alt="" />{" "}
                      {propertyDetail?.bedroomsCount || "0"} Bed
                    </li>
                    <li>
                      <img src="../../images/icon_bath.svg" alt="" />{" "}
                      {propertyDetail?.bathroomCount || "0"} Bath
                    </li>
                    <li>
                      <img src="../../images/icon_kitchen.svg" alt="" />{" "}
                      {propertyDetail?.kitchenCount || "0"} Kitchen
                    </li>
                  </ul>
                  <Rating
                    initialValue={propertyDetail?.propertyRating || 0}
                    iconsCount={5}
                    size={25}
                    readonly
                    transition={true}
                  />

                  <div className="report_p">
                    <h3 onClick={() => setReportModal(true)}>Report </h3>
                  </div>

                  <h3>Description</h3>
                  <p>{propertyDetail?.propertyDescription || ""}</p>
                </div>
                <Milestone
                  BuyTokenApi={BuyTokenApi}
                  setNoOfToken={setNoOfToken}
                  noOfToken={noOfToken}
                  error={error}
                  setError={setError}
                  handleMinting={handleMinting}
                  propertyDetail={propertyDetail}
                  open={open}
                  setOpen={setOpen}
                  serializedData={serializedData}
                />
              </div>
            </div>
            <div className="property_boxes">
              <div className="gap_m">
                <div className="box">
                  <h3>Property Details</h3>
                  <ul className="list_data">
                    <li>
                      <span>Property Type</span>
                      <span className="c_secondary">
                        {propertyDetail?.propertyType || ""}
                      </span>
                    </li>
                    <li>
                      <span>Minting Limit</span>
                      <span className="c_secondary">
                        {serializedData?.mintingLimit || "0"}
                      </span>
                    </li>
                    <li>
                      <span>Token Minted</span>
                      <span className="c_secondary">
                        {serializedData?.totalMinted || "0"}
                      </span>
                    </li>
                    <li>
                      <span>Token On Sale</span>
                      <span className="c_secondary">
                        {serializedData?.tokenOnSale || "0"}
                      </span>
                    </li>
                    {propertyDetail?.userId === userData?._id ? (
                      <li style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className="form_control"
                          style={{ width: "58%", marginBottom: 0 }}
                        >
                          <TextField
                            className="text_field_error"
                            hiddenLabel
                            type="text"
                            placeholder="No. of Tokens on Sale"
                            fullWidth
                            name="no-of-tokens"
                            variant="outlined"
                            value={noOfTokenSale}
                            onChange={(val: any) => {
                              if (isNumber(val.target.value)) {
                                setNoOfTokenSale(val.target.value);
                              }
                            }}
                            style={{
                              border:
                                (!noOfTokenSale && errorSale) ||
                                Number(noOfTokenSale) >
                                  Number(propertyDetail?.totalTokens)
                                  ? "1px solid red"
                                  : "1px solid white",
                              borderRadius: "8px",
                              width: "100%",
                              marginBottom: 0,
                            }}
                          />
                        </div>
                        <PrimaryButton
                          disabled={
                            !noOfTokenSale ||
                            !token ||
                            Number(serializedData?.totalMinted) === 0
                              ? true
                              : false
                          }
                          content="Set On Sale"
                          onClick={() => setTokenOnSaleApi(propertyDetail?._id)}
                        />
                        {/* <p
                          style={{ cursor: "pointer" }}
                          className="status"
                          onClick={() => handleSetOnSale()}
                        >
                          Set On Sale
                        </p> */}
                        {/* {Number(propertyBlockchain?.totalMinted) === 0 ? null : ( */}
                        {/* <PrimaryButton
                        disabled={
                          propertyDetail?.propertyStatus === "PENDING"
                            ? true
                            : false
                        }
                        content="Set On sale"
                        onClick={() => handleSetOnSale()}
                      /> */}
                        {/* )} */}
                      </li>
                    ) : null}
                  </ul>
                </div>
                {propertyDetail?.investmentType === "Ownership" ? (
                  <div className="box">
                    <h3>Amount</h3>
                    <ul className="list_data">
                      <li>
                        <span>Milestone 1 (Upfront)</span>
                        <span className="c_secondary">USDT 285.67</span>
                      </li>
                      <li>
                        <span>Milestone 2 (Contract Signing)</span>
                        <span>
                          <b>USDT</b> 285.67
                        </span>
                      </li>
                      <li>
                        <span>Milestone 3 (Contract Transfer)</span>
                        <span>
                          <b>USDT</b> 285.67
                        </span>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="box">
                    <h3>Profit Details</h3>
                    <ul className="list_data">
                      <li>
                        <span>Profit Sharing Date</span>
                        <span className="c_secondary">
                          {propertyDetail?.profitDistributionDate || ""}
                          {getDaySuffix(
                            propertyDetail?.profitDistributionDate
                          )}{" "}
                          of every month
                        </span>
                      </li>
                      <li>
                        <span>Profit Expected</span>
                        <span>
                          <b>USDT</b> $
                          {propertyDetail?.expectedProfit
                            ? propertyDetail?.expectedProfit.toLocaleString()
                            : 0}
                        </span>
                      </li>
                      <li>
                        <span>Last Month Profit</span>
                        <span>
                          <b>USDT</b> $
                          {propertyTax?.propertyRent
                            ? propertyTax?.propertyRent.toLocaleString()
                            : 0}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="box">
                  <h3>Blockchain Details</h3>
                  <ul className="list_data">
                    <li>
                      <span>Chain Name</span>
                      <span className="c_secondary">Eth/Matrix</span>
                    </li>
                    <li>
                      <span>Contact Address</span>
                      <Tooltip title={copyContract} ref={ContractRef}>
                        <span
                          className="copyAddress"
                          onClick={() => copyContractAddress(ContractAddress)}
                        >
                          {`${ContractAddress.substring(
                            0,
                            5
                          )}...${ContractAddress.substring(
                            ContractAddress?.length - 3
                          )}` || "-"}
                          <ContentCopyIcon />
                        </span>
                      </Tooltip>
                    </li>
                    <li>
                      <span>Owner Wallet</span>
                      <Tooltip title={copyOwner} ref={OwnerRef}>
                        <span
                          className="copyAddress"
                          onClick={() =>
                            copyOwnerAddress(serializedData?.propertyOwner)
                          }
                        >
                          {serializedData?.propertyOwner
                            ? `${serializedData?.propertyOwner.substring(
                                0,
                                5
                              )}...${serializedData?.propertyOwner.substring(
                                serializedData?.propertyOwner?.length - 3
                              )}`
                            : "-"}
                          <ContentCopyIcon />
                        </span>
                      </Tooltip>
                    </li>
                  </ul>
                </div>

                <div className="box">
                  <h3>Your Holdings</h3>
                  <ul className="list_data">
                    <li>
                      <span>No. of Token</span>
                      <span className="c_secondary">
                        {Number(userHolding?.noOfTokenHolds) || 0}
                      </span>
                    </li>
                    <li>
                      <span>Profit Received</span>
                      <span>
                        <b>USDT</b> $
                        {Number(userHolding?.profitClaimedTill)
                          ? Number(
                              userHolding?.profitClaimedTill
                            ).toLocaleString()
                          : 0}
                      </span>
                    </li>
                    <li>
                      {Number(userHolding?.noOfTokenHolds) === 0 ? null : (
                        <span>Your Rating</span>
                      )}

                      {Number(userHolding?.noOfTokenHolds) ===
                      0 ? null : ratingCount === 0 ? (
                        <h3
                          className="rate_now"
                          onClick={() => setOpenModal(true)}
                        >
                          Rate Now
                        </h3>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h3
                            style={{ marginRight: 10 }}
                            onClick={() => setOpenModal(true)}
                            className="rate_now"
                          >
                            Change Rating
                          </h3>
                          <Rating
                            initialValue={ratingCount}
                            iconsCount={5}
                            size={23}
                            allowHover={false}
                            readonly
                            // transition={true}
                          />
                        </div>
                      )}
                    </li>

                    {Number(userHolding?.noOfTokenHolds) <= 0 ? null : (
                      <li style={{ justifyContent: "flex-end" }}>
                        <PrimaryButton
                          disabled={
                            !token || Number(userHolding?.noOfTokenHolds) <= 0
                          }
                          // onClick={handleClaimProfit}
                          onClick={() =>
                            claimProfitApi(
                              Number(userHolding?.profitClaimedTill)
                            )
                          }
                          content="Claim Profit"
                        />
                      </li>
                    )}

                    {/* <li>
                      <span>Total Spply of Token</span>
                      <span>1,011.54</span>
                    </li> */}
                  </ul>
                </div>

                {propertyDetail?.investmentType === "Ownership" ? (
                  <div className="box">
                    <h3>Cash and Financing</h3>
                    <p className="price_label">
                      Offering Breakdown <strong>USDT 240,485</strong>
                    </p>
                    <ul className="gap_p">
                      <li>
                        <strong>Contract Price</strong>
                        <span>USDT 163,300</span>
                      </li>
                      <li>
                        <strong>Closing Cost</strong>
                        <span>USDT 1,191</span>
                      </li>
                      <li>
                        <strong>Cash Reserve</strong>
                        <span>USDT 4,390</span>
                      </li>
                      <li>
                        <strong>Improvement Costs</strong>
                        <span>USDT 57,750</span>
                      </li>
                      <li>
                        <strong>Due Diligence</strong>
                        <span>USDT 685</span>
                      </li>
                      <li>
                        <strong>Acquisition Fee</strong>
                        <span>USDT 13,169</span>
                      </li>
                    </ul>
                    <p className="price_label">
                      Cash Balance <strong>USDT 1,590.03</strong>
                    </p>
                    <h3>Financing</h3>
                    <div className="progress">
                      <div
                        className="progress_status"
                        style={{ width: "40%" }}
                      ></div>
                    </div>
                    <div className="progress_selector">
                      <div className="single">
                        <span></span>
                        <p>
                          <span>Equity</span>
                          <strong>USDT 96,194</strong>
                        </p>
                      </div>
                      <div className="single">
                        <span></span>
                        <p>
                          <span>Debt</span>
                          <strong>USDT 144,291</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="box">
                    <div className="augustdiv">
                      <Button
                        onClick={decreaseMonth}
                        disabled={
                          monthNames[currentMonth.getMonth()] === "January"
                            ? true
                            : false
                        }
                      >
                        <ArrowBackIosIcon />
                      </Button>
                      <h3>{monthNames[currentMonth.getMonth()]} Dividend</h3>
                      <Button
                        onClick={increaseMonth}
                        disabled={
                          monthNames[currentMonth.getMonth()] ===
                          currentMonthName
                        }
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                    </div>
                    <h3>
                      USDT $
                      {totalDividend && propertyTax?.propertyRent
                        ? totalDividend.toLocaleString()
                        : "0"}
                    </h3>
                    <ul className="list_data">
                      <li>
                        <div>
                          <span>Collected Rent </span>{" "}
                          {propertyTax?.propertyRentReceipt ? (
                            <a
                              className="download_a"
                              href={propertyTax?.propertyRentReceipt}
                            >
                              (Download Receipt)
                            </a>
                          ) : null}
                        </div>
                        <span
                          className="c_secondary"
                          style={{ display: "flex" }}
                        >
                          USDT $
                          {propertyTax?.propertyRent
                            ? propertyTax?.propertyRent.toLocaleString()
                            : 0}
                        </span>
                      </li>
                      <li>
                        <div>
                          <span>Property Tax </span>{" "}
                          {propertyTax?.properttTaxReceipt ? (
                            <a
                              className="download_a"
                              href={propertyTax?.properttTaxReceipt}
                            >
                              (Download Receipt)
                            </a>
                          ) : null}
                        </div>
                        <span
                          className="c_secondary"
                          style={{ display: "flex" }}
                        >
                          USDT $
                          {propertyTax?.propertyTax
                            ? propertyTax?.propertyTax.toLocaleString()
                            : 0}
                        </span>
                      </li>
                      <li>
                        <div>
                          <span>Electricity </span>{" "}
                          {propertyTax?.electricityBillReceipt ? (
                            <a
                              className="download_a"
                              href={propertyTax?.electricityBillReceipt}
                            >
                              (Download Receipt)
                            </a>
                          ) : null}
                        </div>
                        <span
                          className="c_secondary"
                          style={{ display: "flex" }}
                        >
                          USDT $
                          {propertyTax?.electricity
                            ? propertyTax?.electricity.toLocaleString()
                            : 0}
                        </span>
                      </li>
                      <li>
                        <div>
                          <span>Water (If applicable) </span>{" "}
                          {propertyTax?.waterBillReceipt ? (
                            <a
                              className="download_a"
                              href={propertyTax?.waterBillReceipt}
                            >
                              (Download Receipt)
                            </a>
                          ) : null}
                        </div>
                        <span
                          className="c_secondary"
                          style={{ display: "flex" }}
                        >
                          USDT $
                          {propertyTax?.water
                            ? propertyTax?.water.toLocaleString()
                            : 0}
                        </span>
                      </li>
                      <li>
                        <span>Transaction Hash</span>
                        <span>
                          <a
                            href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {transactionHash > 13
                              ? transactionHash?.slice(0, 6) +
                                "..." +
                                transactionHash?.slice(-5)
                              : ""}
                          </a>
                        </span>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li>
                        <span>Dividend</span>
                        <span>
                          <b>USDT</b> $
                          {totalDividend && propertyTax?.propertyRent
                            ? totalDividend.toLocaleString()
                            : "0"}
                        </span>
                      </li>
                      <li>
                        <span>Per Share</span>
                        <span className="c_secondary">
                          USDT $
                          {propertyTax?.perShare
                            ? propertyTax?.perShare.toLocaleString()
                            : 0}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}

                <div className="box address_box">
                  <h3>Address</h3>
                  <p>{propertyDetail?.propertyAddress || "-"}</p>
                  <div className="map">
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.36476008907!2d-84.39939902366092!3d33.751238933563066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f503789ca5cb03%3A0x7cf5c19acca6916f!2s125%20Ted%20Turner%20Dr%20SW%2C%20Atlanta%2C%20GA%2030313%2C%20USA!5e0!3m2!1sen!2sin!4v1694434132228!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
                {propertyDetail?.investmentType === "Ownership" ? (
                  <div className="box_group w_100">
                    <h3>Price History for 323 4th St NE</h3>
                    <div className="box">
                      <table className="simple_table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Price</th>
                            <th>Event</th>
                            <th>Source</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>08/23/2023</td>
                            <td>USDT 1,800,000</td>
                            <td>Listed For Sale</td>
                            <td>FMLS GA #7264926</td>
                          </tr>
                          <tr>
                            <td>08/23/2023</td>
                            <td>USDT 1,800,000</td>
                            <td>Sold</td>
                            <td>FMLS GA #7264926</td>
                          </tr>
                          <tr>
                            <td>08/23/2023</td>
                            <td>USDT 1,800,000</td>
                            <td>Sold</td>
                            <td>NA</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                <div className="box_group w_100">
                  <h3>Uploaded document</h3>
                  <div className="Uploadbox_document">
                    {propertyDetail?.ownershipDocuments?.length
                      ? propertyDetail?.ownershipDocuments?.map(
                          (item: string, i: number) => (
                            <div className="upload_document" key={i}>
                              <div className="inner">
                                <figure>
                                  <img
                                    src={
                                      item || "../../images/news-article-02.jpg"
                                    }
                                    alt=""
                                  />
                                  <a className="download_a" href={item}>
                                    <CloudDownloadIcon
                                      style={{ fill: "#121212", opacity: 0.8 }}
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
                <div className="box w_100">
                  <div
                    className="arrow_flex"
                    onClick={() => setOpenInterior(!openInterior)}
                  >
                    <h3>Interior features</h3>
                    <p>
                      {openInterior ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </p>
                  </div>

                  {openInterior ? (
                    <div className="table_responisve">
                      <table>
                        <tbody>
                          <tr>
                            <th>Builtin Features</th>
                            <td>{propertyDetail?.BuiltInFeature || ""}</td>
                            <th>Separate Formal Dining Room</th>
                            <td>{propertyDetail?.diningArea || "No"}</td>
                          </tr>
                          <tr>
                            <th>Bedroom Main Level</th>
                            <td>{propertyDetail?.bedroomMainLevel || ""}</td>
                            <th>Dual Sinks</th>
                            <td>{propertyDetail?.dualSink || "No"}</td>
                          </tr>
                          <tr>
                            <th>Dining Area</th>
                            <td>{propertyDetail?.diningArea || "No"}</td>
                            <th>Separate Shower</th>
                            <td>{propertyDetail?.separateShower || "No"}</td>
                          </tr>
                          {/* <tr>
                          <th>Kitchen Island</th>
                          <td></td>
                          <th>Upper Level Primary</th>
                          <td></td>
                        </tr> */}
                          <tr>
                            <th>Pantry</th>
                            <td>{propertyDetail?.pantry || "No"}</td>
                            <th>Bar</th>
                            <td>{propertyDetail?.Bar || "No"}</td>
                          </tr>
                          <tr>
                            <th>Sitting Area Primary</th>
                            <td>{propertyDetail?.sittingArea || "No"}</td>
                            <th>Walk In Closets</th>
                            <td>{propertyDetail?.walkInClosets || "No"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
                <div className="box w_100">
                  <div
                    className="arrow_flex"
                    onClick={() => setApplianOpen(!appliceOpen)}
                  >
                    <h3>Appliances</h3>
                    <p>
                      {appliceOpen ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </p>
                  </div>

                  {appliceOpen ? (
                    <div className="table_responisve">
                      <table>
                        <tbody>
                          <tr>
                            <th>Dishwasher</th>
                            <td>{propertyDetail?.dishwasher || "No"}</td>
                            <th>Oven</th>
                            <td>{propertyDetail?.Oven || "No"}</td>
                          </tr>
                          <tr>
                            <th>Ice Maker</th>
                            <td>{propertyDetail?.iceMaker || "No"}</td>
                            <th>Heating: Central Furnace</th>
                            <td>
                              {propertyDetail?.heatingCentralFurnace || "No"}
                            </td>
                          </tr>
                          <tr>
                            <th>Dryer</th>
                            <td>{propertyDetail?.dryer || "No"}</td>
                            <th>Refrigerator</th>
                            <td>{propertyDetail?.refrigerator || "No"}</td>
                          </tr>
                          <tr>
                            <th>Microwave</th>
                            <td>{propertyDetail?.microwave || "No"}</td>
                            <th>Washer</th>
                            <td>{propertyDetail?.washingMachine || "No"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
                {propertyDetail?.exteriorLotFeatures?.length ? (
                  <div className="box w_100">
                    <div
                      className="arrow_flex"
                      onClick={() => setExteriorOpen(!exteriorOpen)}
                    >
                      <h3>Exterior and lot features</h3>
                      <p>
                        {exteriorOpen ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </p>
                    </div>

                    {exteriorOpen ? (
                      <div className="table_responisve">
                        <table>
                          <tbody>
                            {propertyDetail?.exteriorLotFeatures?.map(
                              (item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{item}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {propertyDetail?.parkingFeatures?.length ? (
                  <div className="box w_100">
                    <div
                      className="arrow_flex"
                      onClick={() => setParkingOpen(!parkingOpen)}
                    >
                      <h3>Parking features</h3>
                      <p>
                        {parkingOpen ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </p>
                    </div>
                    {parkingOpen ? (
                      <div className="table_responisve">
                        <table>
                          <tbody>
                            {propertyDetail?.parkingFeatures?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="box w_100">
                  <div
                    className="arrow_flex"
                    onClick={() => setBuildingOpen(!buildingOpen)}
                  >
                    <h3>Building and Construction</h3>
                    <p>
                      {buildingOpen ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </p>
                  </div>

                  {buildingOpen ? (
                    <div className="table_responisve">
                      <table>
                        <tbody>
                          <tr>
                            <th>Year built</th>
                            <td>
                              {moment(propertyDetail?.YearBuilt).format(
                                "MMM Do YY"
                              ) || ""}
                            </td>
                            <th>Flooring</th>
                            <td>{propertyDetail?.Flooring || ""}</td>
                          </tr>
                          <tr>
                            <th>Construction materials</th>
                            <td>{propertyDetail?.ContructionMeterial || ""}</td>
                            <th>Roof</th>
                            <td>{propertyDetail?.Roof || ""}</td>
                          </tr>
                          <tr>
                            <th>Architectural style</th>
                            <td>{propertyDetail?.ArchitecturalStyle || ""}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
                <div className="box w_100">
                  <div
                    className="arrow_flex"
                    onClick={() => setOtherOpen(!otherOpen)}
                  >
                    <h3>Other</h3>
                    <p>
                      {otherOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </p>
                  </div>
                  {otherOpen ? (
                    <div className="table_responisve">
                      <table>
                        <tbody>
                          <tr>
                            <th>View</th>
                            <td>{propertyDetail?.View || ""}</td>
                            <th>Waterfront features</th>
                            <td>{propertyDetail?.WaterFrontFeature || ""}</td>
                          </tr>
                          <tr>
                            <th>Waterfront</th>
                            <td>{propertyDetail?.WaterFront || ""}</td>
                            <th>Accessibility features</th>
                            <td>{propertyDetail?.AccessibilityFeature || ""}</td>
                          </tr>
                          <tr>
                            <th>Taxes</th>
                            <td>{propertyDetail?.Taxes || "No"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Gallery */}
      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpen_gallery(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                loop
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {showVideo ? (
                  <>
                    {propertyDetail?.propertyVideos?.length
                      ? propertyDetail?.propertyVideos?.map((item, i) => (
                          <SwiperSlide key={i}>
                            <figure>
                              <video
                                autoPlay
                                muted
                                loop
                                playsInline
                                preload="yes"
                              >
                                <source
                                  src={item || "/images/property-detail-01.jpg"}
                                  type="video/mp4"
                                />
                              </video>
                            </figure>
                          </SwiperSlide>
                        ))
                      : null}
                  </>
                ) : (
                  <>
                    {" "}
                    {propertyDetail?.propertyImages?.length
                      ? propertyDetail?.propertyImages?.map((item, i) => (
                          <SwiperSlide key={i}>
                            <figure>
                              <img
                                src={item || "/images/property-detail-01.jpg"}
                                alt=""
                              />
                            </figure>
                          </SwiperSlide>
                        ))
                      : null}
                  </>
                )}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* rating */}
      <Modal
        className="modal confirmation_modal"
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box className="modalBox_body">
            <Box>
              <Button className="close_btn" onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </Button>
              <h2>Rate This Property</h2>
              <Rating
                style={{ margin: "20px 0px" }}
                initialValue={ratingCount}
                onClick={(newRating: number) => setratingCount(newRating)}
                iconsCount={5}
                size={30}
                // transition={true}
              />

              <div className="form_control" style={{ marginBottom: 9 }}>
                <TextField
                  className="text_field"
                  hiddenLabel
                  multiline
                  rows={5}
                  type="text"
                  placeholder="Type here"
                  fullWidth
                  variant="outlined"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              {/* {error && !comment ? (
                <h6 className="err_msg">This filed is required</h6>
              ) : (
                ""
              )} */}

              {/* <ReactStars
                className="starr"
                half={false}
                count={5}
                onChange={(newRating: number) => setratingCount(newRating)}
                size={30}
                color1={"#DFDFDF"}
                color2={"#CDFF00"}
                value={ratingCount}
              /> */}
              <div className="flexdiv" style={{ marginTop: 20 }}>
                <Button className="btn btn_secondary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* report */}
      <Modal
        className="modal confirmation_modal"
        open={reportModal}
        onClose={() => setReportModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={reportModal}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => {
                  setReportModal(false);
                  setError(false);
                }}
              >
                <CloseIcon />
              </Button>
              <h2 style={{ marginBottom: 30 }}>Report Property</h2>
              <div className="form_control" style={{ marginBottom: 9 }}>
                <TextField
                  className="text_field"
                  hiddenLabel
                  multiline
                  rows={5}
                  type="text"
                  placeholder="Type here"
                  fullWidth
                  variant="outlined"
                  value={report}
                  onChange={(e) => setReport(e.target.value)}
                />
              </div>
              {error && !report ? (
                <h6 className="err_msg">This filed is required</h6>
              ) : (
                ""
              )}
              <div className="flexdiv" style={{ marginTop: 20 }}>
                <Button
                  className="btn btn_secondary"
                  onClick={() => handleReport(propertyDetail?._id)}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default PropertyDetail;
