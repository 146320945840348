/* eslint-disable react-hooks/exhaustive-deps */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { EmbarkComponent, SecondaryButton } from "../../../../components";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../../../helpers";
import { usePostLoginMutation } from "../../../../service/Auth";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../../reducers/authSlice";

const LoginContent = () => {
  const walletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(6, "Password must be at least 6 characters.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
        password: formik.values.password,
        // walletAddress: walletAddress,
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token) || ""
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(response?.data) || ""
          );
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );
          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }
          navigate("/");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("password");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_page">
        <div className="side_container">
          <div className="gap_p">
            <EmbarkComponent />
            <div className="right_side">
              <h2>Login</h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="form_control">
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_control">
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Password"
                    fullWidth
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control">
                  <div className="gap_p">
                    <div className="label_checkbox mb_0">
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={formik.values.remember}
                        name="remember"
                        onChange={formik.handleChange}
                        label="Remember me"
                      />
                    </div>
                    <span onClick={() => navigate("/forgot-password")}>
                      Forgot Password
                    </span>
                  </div>
                </div>
                <div className="form_btn">
                  <SecondaryButton content="Login" />
                </div>
              </form>
              <h4 className="login_line">
                Not a member?{" "}
                <span onClick={() => navigate("/create-account")}>Sign Up</span>
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginContent;
