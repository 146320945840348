import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatch, SetStateAction } from "react";
import PrimaryButton from "../../components/button/primaryButton";
import { GetPropertyDetail, SerializedData } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { isNumber } from "../../utils/validation";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMintPostMutation } from "../../service/Property";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#252525",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: 2,
};

type props = {
  propertyDetail?: GetPropertyDetail;
  BuyTokenApi: (propertyId: string | undefined) => void;
  handleMinting: (propertyId: string) => void;
  setError: Dispatch<SetStateAction<boolean>>;
  error: boolean;
  noOfToken: string;
  setNoOfToken: Dispatch<SetStateAction<string>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  serializedData: SerializedData;
};

const Milestone = ({
  handleMinting,
  propertyDetail,
  setError,
  error,
  setNoOfToken,
  noOfToken,
  BuyTokenApi,
  open,
  setOpen,
  serializedData,
}: props) => {
  const userData = useAuth();
  const token = getFromStorage(STORAGE_KEYS.token);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      remember: false,
      check1: false,
      check2: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      check1: Yup.boolean(),
      check2: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
    },
  });

  return (
    <>
      <div className="right_side">
        {propertyDetail?.investmentType === "Ownership" ? (
          <div className="box">
            <button className="box_edit">
              <img src="../../images/icon_edit.svg" alt="" />
            </button>
            <h4>Luxury villa in Range Park</h4>
            <p>USDT 240,485</p>
            <ul>
              <li>
                <span>Milestone 1 (Upfront)</span>
                <span>USDT 285.67</span>
              </li>
              <li>
                <span>Milestone 2 (Contract Signing)</span>
                <span>USDT 285.67</span>
              </li>
              <li>
                <span>Milestone 3 (Contract Transfer)</span>
                <span>USDT 285.67</span>
              </li>
            </ul>
            {propertyDetail?.propertyStatus === "DISAPPROVED" ? (
              <p style={{ color: "red" }} className="status">
                {propertyDetail?.propertyStatus}
              </p>
            ) : propertyDetail?.propertyStatus === "APPROVED" ? (
              <p className="status">Approval Pending</p>
            ) : null}

            <PrimaryButton
              content="Set On sale"
              // onClick={() => handleSetOnSale()}
            />
          </div>
        ) : (
          <>
            {propertyDetail?.userId === userData?._id ? (
              <div className="box">
                <h4>{propertyDetail?.propertyTitle || ""}</h4>
                <p>USDT {propertyDetail?.tokenValue || 0}/ token</p>
                <ul>
                  <li>
                    <span>Token</span>
                    <span>{propertyDetail?.totalTokens || 0}</span>
                  </li>
                  <li>
                    <span>Minted Token</span>
                    <span>{Number(serializedData?.totalMinted)}</span>
                  </li>
                  <li>
                    <span>Remaining Token</span>
                    <span>
                      {Number(serializedData?.totalToken) -
                        Number(serializedData?.totalMinted)}
                      {/* {propertyDetail?.profitRecieved
                        ? propertyDetail?.profitRecieved.toLocaleString()
                        : 0} */}
                    </span>
                  </li>
                </ul>
                <div className="form_control">
                  <TextField
                    className="text_field_error"
                    hiddenLabel
                    type="text"
                    placeholder="No. of Tokens"
                    fullWidth
                    name="no-of-tokens"
                    variant="outlined"
                    value={noOfToken}
                    onChange={(val: any) => {
                      if (isNumber(val.target.value)) {
                        setNoOfToken(val.target.value);
                      }
                    }}
                    style={{
                      border:
                        (!noOfToken && error) ||
                        Number(noOfToken) > Number(propertyDetail?.totalTokens)
                          ? "1px solid red"
                          : "1px solid white",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                {propertyDetail?.propertyStatus === "APPROVED" ? (
                  <PrimaryButton
                    content="Mint Token"
                    disabled={
                      !noOfToken ||
                      userData?.userStatus === "PENDING" ||
                      userData?.userStatus === "REJECTED" ||
                      !token ||
                      serializedData?.mintingLimit === "0"
                        ? true
                        : false
                    }
                    // className="status"
                    onClick={() => handleMinting(propertyDetail?._id)}
                  />
                ) : (
                  <>
                    {propertyDetail?.propertyStatus === "DISAPPROVED" ? (
                      <p style={{ color: "red" }} className="status">
                        {propertyDetail?.propertyStatus}
                      </p>
                    ) : propertyDetail?.propertyStatus === "PENDING" ? (
                      <p className="status">Approval Pending</p>
                    ) : null}
                  </>
                )}
                {/* <p style={{ marginBottom: 10 }}></p> */}
                {/* {Number(propertyBlockchain?.totalMinted) === 0 ? null : (
                <PrimaryButton
                  disabled={
                    propertyDetail?.propertyStatus === "PENDING" ? true : false
                  }
                  content="Set On sale"
                  onClick={() => handleSetOnSale()}
                />
              )} */}
              </div>
            ) : (
              <div className="box">
                <h4>{propertyDetail?.propertyTitle || ""}</h4>
                <p style={{ paddingBottom: "10px" }}>
                  USDT {Number(serializedData?.tokenPrice) || 0}/ token
                </p>

                <div className="form_control">
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    placeholder="No. of Tokens"
                    fullWidth
                    name="no-of-tokens"
                    variant="outlined"
                    value={noOfToken}
                    onChange={(val: any) => {
                      if (isNumber(val.target.value)) {
                        setNoOfToken(val.target.value);
                      }
                    }}
                  />
                </div>
                <div className="token_sold">
                  <p>Token Sold : </p>{" "}
                  <span>{Number(serializedData?.NoOfSoldToken) || 0}</span>
                  <p>Token Remaining : </p>
                  <span>{Number(serializedData?.tokenOnSale) || 0}</span>
                </div>
                <div className="select_tonn">
                  <p>Or Select</p>
                  <div className="select">
                    <h3 onClick={() => setNoOfToken("10")}>10</h3>
                    <h3 onClick={() => setNoOfToken("20")}>20</h3>
                    <h3 onClick={() => setNoOfToken("30")}>30</h3>
                    <h3 onClick={() => setNoOfToken("40")}>40</h3>
                  </div>
                </div>

                {Number(serializedData?.tokenOnSale) === 0 ? (
                  <h4 style={{ textAlign: "center", color: "#cdff00" }}>
                    Property is not on sale yet.{" "}
                  </h4>
                ) : (
                  <PrimaryButton
                    disabled={
                      !noOfToken ||
                      userData?.userStatus === "PENDING" ||
                      userData?.userStatus === "REJECTED" ||
                      !token ||
                      Number(noOfToken) >= Number(propertyDetail?.totalTokens)
                        ? true
                        : false
                    }
                    content="Continue"
                    // onClick={handleBuyToken}
                    onClick={() => setOpen(true)}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="disclaimer">
          <div style={{ position: "relative" }}>
            <Button
              className="close_btn1"
              onClick={() => setOpen(false)}
              style={{ position: "absolute", top: "-40px", right: "-30px" }}
            >
              <CloseIcon />
            </Button>
            <h4>Disclaimer</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="labeldiv">
                <FormControlLabel
                  style={{ width: "100%" }}
                  control={<Checkbox />}
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                  label="Before buying make sure that you have read all the terms and conditions."
                />

                <FormControlLabel
                  style={{ width: "100%" }}
                  control={<Checkbox />}
                  checked={formik.values.check1}
                  name="check1"
                  onChange={formik.handleChange}
                  label="There is always risk associated with buying tokenized property."
                />
                <FormControlLabel
                  style={{ width: "100%" }}
                  control={<Checkbox />}
                  checked={formik.values.check2}
                  name="check2"
                  onChange={formik.handleChange}
                  label="Block estate just provides the buying and selling platform for the real estate and is not liable for any property disputes."
                />
              </div>
              <div className="btndiv">
                <PrimaryButton
                  content="Buy"
                  onClick={() => BuyTokenApi(propertyDetail?._id)}
                  disabled={
                    !(
                      formik.values.remember &&
                      formik.values.check1 &&
                      formik.values.check2
                    )
                  }
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Milestone;
