/* eslint-disable react-hooks/exhaustive-deps */
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { useCheckWalletAddressMutation } from "../../../service/Auth";
import useAuth from "../../../hooks/useAuth";

const HomeBecomeSeller = () => {
  const userData = useAuth();
  var walletAddress = "";
  const navigate = useNavigate();
  const token = getFromStorage(STORAGE_KEYS.token);

  // console.log("handleJoinUs: ", walletAddress);
  const [checkUser] = useCheckWalletAddressMutation();

  // const handleJoinUs = () => {
  //   if (token) {
  //     navigate("/add-property");
  //   } else if (walletAddress && token === null) {
  //     console.log("hand");

  //     handleCheckUser();
  //   } else {
  //     navigate("/");
  //   }
  // };

  return (
    <section className="cta_sc">
      <div className="conta_iner">
        <div className="inner">
          <div className="hd_1">
            <h2>Become a Seller</h2>
            <p>Tokenize and sell your property in few simple steps </p>
          </div>
          <span
            onClick={() => navigate("/create-account")}
            className="btn_rounded"
          >
            <NorthEastIcon /> Join Us
          </span>
        </div>
      </div>
    </section>
  );
};

export default HomeBecomeSeller;
