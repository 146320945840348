import { useEffect, useState } from "react";
import { EmbarkComponent, SecondaryButton } from "../../../../components";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../../../helpers";
import OtpInput from "react-otp-input";
import {
  useForgotPasswordMutation,
  useOtpVerficationMutation,
} from "../../../../service/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import { setCredentials, temporaryToken } from "../../../../reducers/authSlice";
import { useAppDispatch } from "../../../../hooks/store";

const otpStyle = {
  width: "60px",
  height: "60px",
  "box-sizing": "border-box",
  margin: "30px 30px 0 0",
};

const VerificationOtp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const [verification, verificationMutation] = useOtpVerficationMutation();
  const [ForgotPasswordMethod, forgotMutation] = useForgotPasswordMutation();
  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        key: state?.email,
        otp: otp,
      };

      try {
        const response = await verification(body).unwrap();
        if (response?.statusCode === 200) {
          if (state?.screenType === "forgot") {
            setToStorage(
              STORAGE_KEYS.tempToken,
              JSON.stringify(response?.data?.token)
            );
            dispatch(
              temporaryToken({
                tempToken: response?.data?.token || null,
              })
            );
            navigate("/reset-password", { state: state?.email, replace: true });
          } else {
            navigate("/", { replace: true });
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token) || ""
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data) || ""
            );
            dispatch(
              setCredentials({
                user: response?.data,
                token: response?.data?.token || "",
              })
            );
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResend = async () => {
    let body = {
      key: state?.email,
    };

    try {
      const response = await ForgotPasswordMethod(body).unwrap();
      if (response?.statusCode === 200) {
        setCountDown(59);
        successToast(response?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <section className="auth_page otp_scren">
      <Loader
        isLoad={verificationMutation?.isLoading || forgotMutation?.isLoading}
      />
      <div className="side_container ">
        <div className="gap_p">
          <EmbarkComponent />
          <div className="right_side">
            <h2>Email Verification</h2>
            <div className="form_control">
              <label>Enter Your OTP </label>
              <div className="otp_sc">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={otpStyle}
                  inputType="tel"
                />
                <br />
                {error && otp.length !== 4 ? (
                  <h6 className="err_msg">This field is required</h6>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form_control"></div>
            <div className="form_control"></div>



            <div className="form_btn">
              <SecondaryButton
                content="Submit"
                onClick={() => handleSubmit()}
              />
            </div>

            {countDown === 0 ? (
              <div className="rsnd_btn">
                <h5 onClick={handleResend} className="resend_txt resend_txt_2 ">
                  Resend OTP
                </h5>
              </div>
            ) : (
              <h5 className="resend_txt">
                The verification code will expire in
                <span className="count"> 00 : {countDown}</span>
              </h5>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerificationOtp;
