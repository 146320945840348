import React from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import moment from "moment";

const Footer = () => {
  return (
    <div className="site_footer ut_spc">
      <div className="conta_iner ub_spc">
        <div className="gap_p">
          <div className="single">
            <div className="logo">
              <figure style={{ marginTop: "-27px" }}>
                <img src="/images/logo.png" alt="" />
              </figure>
            </div>
            <p>
              Blockestate is the leading realestate tokenization company with{" "}
              <br />
              over 500+ properties listed.
            </p>
          </div>
          <div className="single">
            <h2>COMPANY</h2>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li className="scl_icon">
                Follow us on :
                <div className="f_flex">
                  <Link
                    to="https://www.instagram.com/blocktunix/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </Link>
                  <Link
                    to="https://www.facebook.com/Blocktunix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="single">
            <h2>HELP CENTER</h2>
            <ul>
              <li>
                <Link to="">Business Model</Link>
              </li>
              <li>
                <Link to="/blog-listing">Education Center</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copy_right">
        <div className="conta_iner">
          <p>
            © {moment().year()} Blockestate, Inc. All rights reserved | Real
            Estate Tokenization | <Link to="/privacy-policy">Privacy Policy</Link>{" "}
            | <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
