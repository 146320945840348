import { useNavigate } from "react-router-dom";
import { GetPropertyDetail } from "../../types/General";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useChangeFavStatusMutation } from "../../service/Property";
import { errorToast, showWarning } from "../../helpers";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Skeleton } from "@mui/material";

type props = {
  propertyDetail?: GetPropertyDetail[];
  status?: boolean;
  bought?: boolean;
  favItems: GetPropertyDetail[];
  setFavItems: Dispatch<SetStateAction<any>>;
};

const PropertyGrid = ({
  propertyDetail,
  status,
  bought,
  favItems,
  setFavItems,
}: props) => {
  const navigate = useNavigate();
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [metamask, setMetamask] = useState<any>();
  const [chainId, setChainId] = useState<string>();

  useEffect(() => {
    if (window.ethereum) {
      setMetamask(window.ethereum);
      setChainId(window.ethereum.networkVersion);
    } else {
      console.log("meta mask not detected");
    }
  }, []);

  const handleFav = async (e: any, item: GetPropertyDetail) => {
    e.stopPropagation();
    try {
      const response = await changeFavStatus({
        property_id: item?._id,
      }).unwrap();
      if (response?.statusCode === 200) {
        if (favItems?.includes(item)) {
          let indexToDelete = favItems.indexOf(item);
          favItems.splice(indexToDelete, 1);
          setFavItems([...favItems]);
        } else {
          favItems?.push(item);
          setFavItems([...favItems]);
        }
      } else {
        errorToast("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ul className="property_grid gap_p">
      {propertyDetail?.length
        ? propertyDetail?.map((item) => {
            return (
              <li
                key={item?._id}
                onClick={() => navigate(`/property-detail/${item?._id}`)}
              >
                <div className="single_property">
                  {item ? (
                    <figure>
                      {bought ? null : (
                        <>
                          {status ? (
                            <p
                              style={{
                                color:
                                  item?.propertyStatus === "PENDING"
                                    ? "black"
                                    : "white",
                                background:
                                  item?.propertyStatus === "APPROVED"
                                    ? "green"
                                    : item?.propertyStatus === "PENDING"
                                    ? "gold"
                                    : "red",
                              }}
                            >
                              {item?.propertyStatus || ""}
                            </p>
                          ) : (
                            <>
                              {favItems?.includes(item) ? (
                                <FavoriteIcon
                                  onClick={(e) => handleFav(e, item)}
                                />
                              ) : (
                                <FavoriteBorderIcon
                                  onClick={(e) => handleFav(e, item)}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}

                      <img src={item?.propertyImages?.[0] || ""} alt="" />
                    </figure>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height="100%"
                      animation="wave"
                      style={{ borderRadius: 20 }}
                    />
                  )}

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>{item?.propertyTitle || ""}</h4>{" "}
                    <Rating
                      initialValue={item?.propertyRating || 0}
                      iconsCount={5}
                      size={15}
                      readonly
                    />
                  </div>

                  <p>
                    {item?.propertyAddress || ""}, {item?.city} -{" "}
                    <span>{item?.investmentType || ""}</span>
                  </p>

                  <h3>
                    $
                    {item?.propertyPrice
                      ? item?.propertyPrice.toLocaleString("en-US")
                      : 0}
                  </h3>
                  <ul>
                    <li>
                      <img src="/images/icon_bed.svg" alt="" />{" "}
                      {item?.bedroomsCount || "0"} Bed
                    </li>
                    <li>
                      <img src="/images/icon_bath.svg" alt="" />{" "}
                      {item?.bathroomCount || "0"} Bath
                    </li>
                    <li>
                      <img src="/images/icon_kitchen.svg" alt="" />{" "}
                      {item?.kitchenCount || "0"} Kitchen
                    </li>
                  </ul>
                </div>
              </li>
            );
          })
        : null}
    </ul>
  );
};

export default PropertyGrid;
