import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.LandingPage />} />
        <Route path="/login" element={<Pages.Login />} />
        <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
        <Route path="/reset-password" element={<Pages.ResetPassword />} />
        <Route path="/change-password" element={<Pages.ChangePassword />} />
        <Route path="/create-account" element={<Pages.CreateAccount />} />
        <Route path="/upload-document" element={<Pages.UploadDocunment />} />
        <Route path="/verification" element={<Pages.Verification />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Pages.Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/view-profile"
          element={
            <ProtectedRoutes>
              <Pages.ViewProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/resubmit-doc"
          element={
            <ProtectedRoutes>
              <Pages.EditDocuments />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-property"
          element={
            <ProtectedRoutes>
              <Pages.AddPropertyInfo />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/upload-picture-video/:id"
          element={
            <ProtectedRoutes>
              <Pages.UploadPictureAndVideo />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/stake-months-profit"
          element={
            <ProtectedRoutes>
              <Pages.StakeMonthsProfit />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/owner-transfer-add-property"
          element={<Pages.OwnerTransferAddPropertyInfo />}
        />
        <Route
          path="/owner-transfer-upload-picture-video"
          element={<Pages.OwnerTransferUploadPictureAndVideo />}
        />
        <Route path="/property-detail/:id" element={<Pages.PropertyDetail />} />
        <Route
          path="/ownership-transfer-property-detail"
          element={<Pages.OwnershipTransferPropertyDetail />}
        />
        <Route path="/property-market" element={<Pages.PropertyMarket />} />
        <Route path="/property-location" element={<Pages.PropertyLocation />} />
        <Route
          path="/transactions"
          element={
            <ProtectedRoutes>propertyListing_sc
              <Pages.Transactions />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/my-properties"
          element={
            <ProtectedRoutes>
              <Pages.MyProperties />
            </ProtectedRoutes>
          }
        />
        <Route path="/blog-listing" element={<Pages.BlogListing />} />
        <Route path="/blog-detail/:id" element={<Pages.BlogDetail />} />
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          element={<Pages.TermsConditions />}
        />
        <Route path="/contact-us" element={<Pages.ContactUs />} />
        <Route path="/about-us" element={<Pages.AboutUs />} />
        <Route path="/favourite-properties" element={<Pages.FavProperty />} />
        <Route path="/news-detail/:id" element={<Pages.NewsDetail />} />
        <Route path="/news-listing" element={<Pages.NewsListing />} />
      </Routes>
    </div>
  );
};

export default Routing;


