import Layout from "../../../../layout";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";
import { Backdrop, Box, Button, Fade, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
const ViewProfile = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Layout>
      <main>
        <section className="auth_page ut_spc">
          <div className="conta_iner">
            <div className="right_side small_width">
              <form>
                <div className="form_control edit-style">
                  <div className="upload_image">
                    <figure>
                      <img
                        src={
                          userData?.profilePic
                            ? userData?.profilePic
                            : "/images/user_placeholder.svg"
                        }
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="kyc">
                    <p
                      style={{
                        color:
                          userData?.userStatus === "REJECTED" ? "red" : "white",
                      }}
                    >
                      KYC
                      {userData?.userStatus === "PENDING" ? (
                        <span>
                          Pending <ErrorIcon style={{ color: "#CDFF00" }} />
                        </span>
                      ) : userData?.userStatus === "REJECTED" ? (
                        <span className="red-text">
                          Rejected{" "}
                          <p onClick={() => setOpenModal(true)}>Reason</p>
                        </span>
                      ) : userData?.userStatus === "APPROVED" ? (
                        <span>
                          Verified <VerifiedIcon style={{ color: "green" }} />
                        </span>
                      ) : null}
                    </p>
                    <h4
                      onClick={() => navigate("/profile")}
                      className="btn btn_primary br"
                    >
                      Edit
                    </h4>
                  </div>
                </div>
                <div className="flex-pr">
                  <h4>Name</h4>
                  <h5>
                    {userData?.firstName || ""} {userData?.lastName || ""}
                  </h5>
                </div>
                <div className="flex-pr">
                  <h4>Email</h4>
                  <h5>{userData?.email || ""}</h5>
                </div>
                <div className="flex-pr">
                  <h4>Phone</h4>
                  <h5>
                    {" "}
                    {userData?.countryCode
                      ? (userData?.countryCode.includes("+") ? "" : "+") +
                      userData?.countryCode
                      : null}{" "}
                    {userData?.phone || ""}
                  </h5>
                </div>

                <div className="flex-pr">
                  <h4>Address</h4>
                  <h5>{userData?.address1 || ""}</h5>
                </div>
                <div className="flex-pr">
                  <h4>Location</h4>
                  <h5>{userData?.locationKey || ""}</h5>
                </div>
                <div className="flex-pr">
                  <h4>Zipcode</h4>
                  <h5>{userData?.zipCode || ""}</h5>
                </div>
                <div className="flex-pr">
                  <h4>Wallet Address</h4>
                  <h5>{userData?.walletAddress || ""}</h5>
                </div>
                <h3>Uploaded Documents</h3>
                <div className="form_control">
                  <div className="gap_p">
                    <div className="upload_document w_50">
                      <div className="inner">
                        <figure>
                          <img src={userData?.documentFrontImage} alt="" />
                        </figure>
                      </div>
                    </div>
                    <div className="upload_document w_50">
                      <div className="inner">
                        <figure>
                          <img src={userData?.documentBackImage} alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Modal
        className="modal confirmation_modal"
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box className="modalBox_body">
            <Box>
              <Button className="close_btn" onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </Button>
              <h2>KYC Rejection Reason</h2>
              <p>{userData?.rejectReason}</p>
              <div className="flexdiv">
                <Button
                  className="btn btn_secondary"
                  onClick={() => {
                    setOpenModal(false);
                    navigate("/resubmit-doc");
                  }}
                >
                  Re-Submit
                </Button>
              </div>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Layout>
  );
};

export default ViewProfile;
