import { END_POINTS } from "../helpers";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostSignupBody = {
  firstName: string;
  lastName: string;
  phone: string;
  countryCode: string;
  key: string;
  address1: string;
  userLocation: {
    lat: number;
    lng: number;
  };
  zipCode: number | string;
  password: string;
  profilePic: string;
};

type PostChangePasswordBody = {
  oldPassword: string;
  password: string;
};

type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutUpdateProfileBody = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  countryCode?: string;
  email?: string;
  address1?: string;
  userLocation?: {
    lat: number;
    lng: number;
  };
  zipCode?: number | string;
  profilePic?: string;
  documentFrontImage: string;
  documentBackImage: string;
};

type PutUpdateProfileByIdResponse = {
  userId: string;
  body: PutUpdateProfileByIdBody;
};

type PutUpdateProfileByIdBody = {
  documentFrontImage: string;
  documentBackImage: string;
  document: string;
};

type PostLogoutResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    checkWalletAddress: builder.mutation<
      CommonResponseType & { data: User },
      {
        walletAddress: string | number | boolean | object;
      }
    >({
      query: (body) => ({
        url: END_POINTS.checkWalletAddress,
        method: "POST",
        body,
      }),
    }),
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      {
        key: string;
        password: string;
        // walletAddress: string | number | boolean | object;
      }
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postSignup: builder.mutation<
      CommonResponseType & { data: User },
      PostSignupBody
    >({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),

    forgotPassword: builder.mutation<CommonResponseType, { key: string }>({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),

    getProfile: builder.query<CommonResponseType & { data: User }, unknown>({
      query: () => ({
        url: END_POINTS.get_profile,
        method: "GET",
      }),
    }),

    putUpdateProfile: builder.mutation<
      CommonResponseType & { data: User },
      PutUpdateProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),

    postLogout: builder.mutation<PostLogoutResponse, unknown>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),

    otpVerfication: builder.mutation<
      CommonResponseType & { data: string },
      { key: string | undefined; otp: string }
    >({
      query: (body) => ({
        url: END_POINTS.verify_otp,
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      {
        statusCode: number;
        data: string;
        message: string;
      },
      { key: string; password: string }
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),

    putUpdateProfileById: builder.mutation<
      CommonResponseType,
      PutUpdateProfileByIdResponse
    >({
      query: ({ userId, body }) => ({
        url: `${END_POINTS.update_profile_by_id}/${userId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});
export const {
  useCheckWalletAddressMutation,
  usePostLoginMutation,
  usePostSignupMutation,
  usePostChangePasswordMutation,
  useForgotPasswordMutation,
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
  usePostLogoutMutation,
  useOtpVerficationMutation,
  useResetPasswordMutation,
  usePutUpdateProfileByIdMutation,
} = authApi;
